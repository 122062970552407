.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.avatar-container {
  position: relative;
  border-radius: 15%;
  padding: 2%;
  box-shadow: "0 4px 8px rgba(0, 0, 0, 0.3)";
}

.avatar-container div {
  /* height: 100px; */
  display: flex;
  align-items: center;
}

.avatar-container img {
  height: auto;
  aspect-ratio: 4/4;
  object-fit: contain;
}

.avatar-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  /* background-color: rgba(0, 0, 0, 0.7); */
  color: #fff;
  padding: 1px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.sb-avatar__image {
  height: fit-content;
}

