@tailwind base;
@tailwind components;
@tailwind utilities;

.hide-badge > .grecaptcha-badge {
  display: none !important;
}

/* Dark theme autofill styles */
.dark input:-webkit-autofill,
.dark input:-webkit-autofill:hover,
.dark input:-webkit-autofill:focus,
.dark input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #1e293b inset !important;
  -webkit-text-fill-color: #ffffff !important;
  border: 1px solid #4a5568 !important;
  caret-color: #ffffff;
}

/* Google Maps InfoWindow */
.gm-ui-hover-effect {
  margin-top: 5px !important;
  margin-right: 10px !important;
}

/* Scrollbar Track */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

.PhoneInput {
  display: flex;
  align-items: center;
}
.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: 0.35rem;
}
.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 0;
  opacity: 0;
  cursor: pointer;
}
.PhoneInputCountrySelect:focus {
  box-shadow: none;
}
.PhoneInputCountryIcon {
  height: 20px;
  width: 30px;
}
.PhoneInputCountryIconImg {
  display: block;
  height: 100%;
  width: 100%;
}

.PhoneInputCountrySelectArrow {
  display: block;
  content: "";
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
  margin-left: 6px;
  border-style: solid;
  border-color: #2684ff;
  border-top-width: 0;
  border-bottom-width: 1px;
  border-left-width: 0;
  border-right-width: 1px;
  opacity: 1;
}

.PhoneInputInput {
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  width: 100%;
  padding: 1;
  height: 36px;
}
.PhoneInputInput:focus {
  box-shadow: 0 0 0 1px #2684ff;
  border-color: #2684ff;
}

.tree {
  position: relative;
  margin-top: 10px;
  padding: 10px;
  font-size: 0.85rem;
  line-height: 1.5;
  color: #212529;
}
.tree span {
  font-size: 15px;
  letter-spacing: 0.4px;
}

.tree .ul {
  padding-left: 5px;
}
.tree .ul .li {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
}
.tree .ul .li:before {
  position: absolute;
  top: 15px;
  left: 0;
  width: 40px;
  height: 1px;
  margin: auto;
  content: "";
  background-color: #d3cfcf;
}
.tree .ul .li:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 1px;
  height: 100%;
  content: "";
  background-color: #d3cfcf;
}
.tree .ul .li:last-child:after {
  height: 15px;
}

.tree .ul a:hover {
  text-decoration: none;
}

/* Joint.js custom styles start here */
.paper-wrapper {
  position: relative;
  width: 980px;
  height: 800px;
  overflow: auto;
  border: 1px solid black;
}

/*  Paper  */
.paper-container {
  position: absolute;
  top: 0;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

/* Halo action styles */
.halo-action-button {
  background-color: white;
  border: 1px solid black;
  border-radius: 50%;
  cursor: pointer;
  width: 23px;
  height: 24px;
  text-align: center;
  line-height: 16px;
  font-size: 14px;
}
/* Joint.js custom styles end here */

#minmax-range::-webkit-slider-runnable-track {
  accent-color: var(--slider-color);
}

#minmax-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  background: var(--slider-color);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#minmax-range::-webkit-slider-thumb:hover {
  width: 20px;
  height: 20px;
  box-shadow: 0 0 0 4px var(--slider-shadow-color);
}

.strikethrough {
  position: relative;
}
.strikethrough::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  border-top: 1px solid black; /* You can change the color and thickness here */
  pointer-events: none; /* Allows clicking through the line */
}

/* Ensure a fixed height for the container */
.week td {
  text-align: right;
  position: relative;
  padding: 2px 5px;
  /* width: 13%; */
  vertical-align: top;
}

.week .date {
  position: static;
  top: 2px;
  right: 10px;
}

.events-wrapper {
  position: static;
  top: 20px;
}

.event {
  width: 95%;
  height: 20px;
  display: inline-block;
  top: 5px;
  position: relative;
  margin-right: 10px;
  /* margin-bottom: 5px; */
  /* padding-left: 5px; */
  text-align: left;
}

.event .event-name {
  display: inline;
  font-weight: bold;
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}

.datepicker-dark-mode {
  .react-datepicker__header {
    background-color: #1e293b !important;
  }

  .react-datepicker__current-month,
  .react-datepicker__day-name,
  .react-datepicker__day {
    color: white !important;
  }

  .react-datepicker__day:hover {
    color: #0f172a !important;
  }

  .react-datepicker__day--disabled,
  .react-datepicker__day--disabled:hover {
    color: #475569 !important;
  }

  .react-datepicker__month {
    background-color: #334155 !important;
    margin: 0px !important;
    padding: 0.4rem !important;
  }

  .react-datepicker__input-container input {
    background-color: #0f172a !important;
    color: white !important;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: white !important; /* #0f172a bg color */
    color: #0f172a !important;
  }

  .react-datepicker__day--selected {
    background-color: white !important;
    color: #0f172a !important;
  }

  /* time */

  .react-datepicker__header react-datepicker__header--time {
    background-color: #1e293b !important;
  }

  .react-datepicker__time {
    background-color: #334155 !important;
  }

  .react-datepicker-time__header {
    color: white !important;
  }

  .react-datepicker__time-list {
    color: white !important;
    height: 199px !important;
  }

  .react-datepicker__time-list-item--selected,
  .react-datepicker__time-list-item:hover {
    background-color: white !important;
    color: #0f172a !important;
  }

  .react-datepicker {
    display: flex !important;
    background-color: #0f172a !important;
  }
}

.datepicker-light-mode {
  .react-datepicker {
    display: flex !important;
  }

  .react-datepicker__input-container input {
    background-color: #f8f8f8 !important;
  }
}

.dark {
  .PhoneInputInput {
    background-color: #1e293b;
    border-color: #4a5568;
  }
  .PhoneInputCountrySelect {
    background-color: #1e293b;
  }
}

.persetIcon {
  height: 16px;
  width: 25px;
  border-radius: 90%;
  rotate: -50deg;
}

.table-fixed-layout {
  width: 100%;
  table-layout: fixed;
}

.table-cell-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  padding-right: 10px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .table-cell-ellipsis {
    max-width: 100px;
  }
}
/* Media query for responsive adjustments */
@media (max-width: 768px) {
  .table-cell-ellipsis {
    max-width: 100px;
  }
}

@media (max-width: 480px) {
  .table-cell-ellipsis {
    max-width: 80px;
  }
}

.table-cell-break {
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.tags-container {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

/* Before hover: show ellipsis */
.ellipsis-tags {
  display: block;
  padding-right: 8px;
}

/* On hover: display as flex */
.flex-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  white-space: normal;
  overflow: visible;
}
.badge-elipsis {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
